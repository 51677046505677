@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(/src/fonts/Gilroy/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(/src/fonts/Gilroy/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url(/src/fonts/Gilroy/Gilroy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(/src/fonts/Gilroy/Gilroy-Light.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Gilroy-Regular', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
caption{
  padding: 1.6vh 1.6vh 2.4vh 1.6vh; 
}
