.App {
  text-align: center;
  background-color: black;
}


.App-header {
  background: url('./imgs/bg.png');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative; /* Важно для позиционирования элементов внутри */
  overflow: hidden; /* Обрезка содержимого, выходящего за границы контейнера */
}

.App-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(10px); /* Размытие фона */
  z-index: 1; /* Размытие будет позади контента */
}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 32px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  position: relative;
}

h2 {
  font-size: 22px;
}

p {
  font-size: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 46px;
  border-radius: 9999px;
  background: rgba(0, 180, 160, 0.12);
}

@media (max-width: 450px) {
  .App-header{
    background: url('./imgs/mobileBack.jpg');
    background-size: 200%;
  }
}